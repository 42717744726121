///
/// Telemetry by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Icon */

	.icon {
		@include icon;
		border-bottom: none;
		position: relative;

		> .label {
			display: none;
		}
	}