///
/// Telemetry by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Button */

	input[type="submit"],
	input[type="reset"],
	input[type="button"],
	button,
	.button {
		@include vendor('appearance', 'none');
		@include vendor('transition', (
			'background-color #{_duration(transition)} ease-in-out',
			'color #{_duration(transition)} ease-in-out'
		));
		border-radius: _size(border-radius);
		border: 0;
		cursor: pointer;
		display: inline-block;
		font-size: 0.9em;
		font-weight: _font(weight-bold);
		height: 3.5em;
		letter-spacing: 0.05em;
		line-height: 3.5em;
		padding: 0 2em;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		white-space: nowrap;

		&.icon {
			&:before {
				margin-right: 0.5em;
			}
		}

		&.fit {
			display: block;
			margin: 0 0 (_size(element-margin) * 0.5) 0;
			width: 100%;
		}

		&.small {
			font-size: 0.7em;
		}

		&.big {
			font-size: 1.1em;
		}

		&.wide {
			min-width: 15em;
		}

		&.disabled,
		&:disabled {
			@include vendor('pointer-events', 'none');
			opacity: 0.25;
		}
	}

	@mixin color-button($p: null) {
		$highlight: _palette($p, highlight);

		input[type="submit"],
		input[type="reset"],
		input[type="button"],
		button,
		.button {
			background-color: transparent;
			box-shadow: inset 0 0 0 _size(border-width) _palette($p, fg);
			color: _palette($p, fg-bold) !important;

			&:hover {
				background-color: _palette($p, border-bg);
			}

			&:active {
				background-color: _palette($p, border2-bg);
			}

			&.icon {
				&:before {
					color: _palette($p, fg-light);
				}
			}

			&.special {
				box-shadow: none;

				@if $p == $highlight {
					background-color: _palette($p, fg-bold);
					color: _palette($p, bg) !important;

					&.icon {
						&:before {
							color: _palette($p, bg) !important;
						}
					}
				}
				@else {
					background-color: _palette($highlight, bg);
					color: _palette($highlight, fg-bold) !important;

					&.icon {
						&:before {
							color: _palette($highlight, fg-bold) !important;
						}
					}

					&:hover {
						background-color: lighten(_palette($highlight, bg), 5);
					}

					&:active {
						background-color: darken(_palette($highlight, bg), 5);
					}
				}
			}
		}
	}

	@include color-button;