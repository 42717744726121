.pagination
{
    width: 100%;
    text-align: right;
    float: right;

    ul {
        list-style-type: none;

        li
        {
            display: inline-block;
            padding-left: 0;

            &:not(:last-child)::after {
                content: '/';
                margin-left: 3px;
                margin-right: 3px;
            }

            &.active {
                a {
                    text-decoration: none;
                    cursor: default;
                }
            }
        }
    }
}